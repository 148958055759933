$(function () {
	$(".dignity__item").on("mouseenter", function (event) {
		event.preventDefault();
		$(this)
			.children(".dignity__description")
			.removeClass("dignity__description--hidden")
			.addClass("dignity__description--visible");
	});

	$(".dignity__item").on("mouseleave", function (event) {
		event.preventDefault();
		$(this)
			.children(".dignity__description")
			.removeClass("dignity__description--visible")
			.addClass("dignity__description--hidden");
	});
});
