window.addEventListener("load", function(e) {
    var control = document.querySelector(".middleMenu__control");
    var menuBlock = document.querySelector(".middleMenu__block");
    document.addEventListener("click", function(e) {
        if (control === e.target) {
            e.preventDefault();
            menuBlock.classList.toggle("middleMenu__block--hidden")
        } else if (e.target.closest(".middleMenu__block") || menuBlock.classList.contains("middleMenu__block--hidden")){
            return;
        }
        else{
            menuBlock.classList.add("middleMenu__block--hidden")
        }
    }, true)
});
// Второй вариант
/*
window.addEventListener('load', function (e) {
    let control = document.querySelector('.middleMenu__control');
    let menuBlock = document.querySelector('.middleMenu__block');

    document.addEventListener('click', function(e){
        if(control !== e.target){
            menuBlock.classList.add("middleMenu__block--hidden");
        }

    });
    control.addEventListener('click', function (e) {
        e.preventDefault();

        if(menuBlock.classList.contains("middleMenu__block--hidden")){
            menuBlock.classList.remove("middleMenu__block--hidden")

        }else {
            menuBlock.classList.add("middleMenu__block--hidden");

        }
    })

});*/
