$(document).ready(function() {
    $('.button--minus').on("click", function () {
        var $input = $(this).parent().find('.shk-count');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        SHK.recountItemAll();
        return false;
    });

    $('.button--plus').on("click",function () {
        var $input = $(this).parent().find('.shk-count');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        SHK.recountItemAll();
        return false;
    });
});