$(document).ready(function () {
	$(".sslider__inner").slick({
		infinite: false,
		speed: 700,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
	});
});

//JQ инициализация слайдера
$(document).ready(function () {
	//Применяем настройки
	$(".sliderTab__inner").slick(getSliderSettings());
});
//Настройки слайдера
function getSliderSettings() {
	return {
		infinite: false,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
}

// Функция формирования и отправки AJAX
function ajaxGet(method, url, type, callback) {
	const xhr = new XMLHttpRequest();
	let f = callback || function (data) {};
	xhr.open(method, url);
	xhr.setRequestHeader("Content-Type", type);
	xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
	xhr.send();
	xhr.addEventListener("load", () => {
		f(xhr.responseText);
	});
}

//JS Настройка табов и отправка AJAX
window.addEventListener("load", () => {
	// Переключение табов
	let tabsInner = document.getElementsByClassName("tabs__inner");
	for (let i = 0; i < tabsInner.length; i++) {
		tabsInner[i].addEventListener("click", (e) => {
			if (e.target.classList.contains("tabs__item")) {
				tabsInner[i]
					.querySelector(".tabs__item--active")
					.classList.remove("tabs__item--active");
				e.target.classList.add("tabs__item--active");
				let text = e.target.innerHTML;
				let block = tabsInner[i].dataset["name"];
				let params = "?name=" + text + "&block=" + block;
				let container = e.target
					.closest(".sliderTab")
					.querySelector(".sliderTab__inner");
				ajaxGet(
					"GET",
					"/ajax" + params,
					"application/x-www-form-urlencoded",
					(data) => {
						$(container).slick(
							"unslick"
						); /* JQ методы slick слайдера */
						$(container)
							.children(".item")
							.remove(); /* JQ методы slick слайдера */
						container.innerHTML = data; /* записываем результат */
						$(container).slick(
							getSliderSettings()
						); /* JQ методы slick слайдера */
					}
				);
			}
		});
	}
});
